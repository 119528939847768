
import Seo from '../../components/common/Seo';

const CookiesPolicy = () => {
  return (
    <>
      <Seo 
        title="VIR BIKE Cookies Policy - Enhancing Your Experience"
        description="Review VIR BIKE's cookies policy and understand how we use cookies to improve your browsing experience on our website."
        name="VIR MOBILITY PVT. LTD"
        type="webapp"
        />
      <section id="cookiesPolicy" className="section">
        <div className="cookies-container container">
          <h1>Cookies Policy</h1>
          <p>This website utilizes cookies, which are tiny files that are downloaded to your device, to enhance your experience. This article explains what information the cookies acquire, how we use it, and why we sometimes store these cookies. We will also mention how you can prevent the storing of these cookies, though this may reduce or break' certain aspects of the website's functionality.</p>
          <p>We use cookies for a variety of reasons, all of which are mentioned below. Unfortunately, in most cases, there are no industry standards for disabling cookies without completely disabling the features and functionality they add to the website. It is recommended that you leave on all cookies if you are uncertain whether you need them or not, in case they are used to provide a service you use.</p>
          <p>You can hinder the setting of cookies by adjusting the settings on your browser (see your browser’s Help for how to do this). Be aware that disabling cookies will affect the functionality of this and several other websites you visit. Disabling cookies will typically result in also disabling certain features and functionality of the website. Therefore, it is recommended that you not disable cookies.</p>
          <p>We set cookies for various reasons, some of which are listed below:</p>
          <ul>
            <li className='mb-3'>- Account-related cookies: If you create an account with us, we will use cookies for the management of the signup process and general administration. These cookies are usually deleted when you log out, though in some cases, they may remain afterward to recall your site preferences when logged out.</li>
            <li className='mb-3'>- Login-related cookies: We use cookies when you are logged in so that we can remember this fact. This prevents you from having to log in each time you visit a new page. These cookies are typically removed or cleared when you log out to make sure you can only access restricted features and areas when logged in.</li>
            <li className="mb-3">- Email newsletters-related cookies: This website offers newsletter or email subscription services, and cookies may be used to remember if you are already registered and whether to show certain notifications which may only be valid to subscribed/unsubscribed users.</li>
            <li className="mb-3">- Orders processing-related cookies: This website offers e-commerce or payment facilities, and some cookies are essential to make sure your order is remembered between pages so that we can process it properly.</li>
            <li className="mb-3">- Forms-related cookies: When you submit data through a form, such as those found on contact pages or comment forms, cookies may be set to remember your user details for future correspondence.</li>
            <li className="mb-3">- Site preferences cookies: In order to offer you a great experience on this website, we provide the capability to set your preferences for how this website runs when you use it. To remember your preferences, we need to set cookies so that this information can be called whenever you interact with a page that is affected by your preferences.</li> 
          </ul>
          <p>Third-Party Cookies: In some special cases, we also use cookies provided by trusted third parties. The following section details which third-party cookies you may encounter through this website.</p>
          <p>This website uses Google Analytics, which is one of the most extensive and trusted analytics solutions on the web for aiding us to comprehend how you use the website and ways that we can improve your experience. These cookies may track details such as how long you spend on the website and the pages you visit so we can continue to generate engaging content.</p>
          <p>For more information on Google Analytics cookies, see the official Google Analytics page.</p>
          <p>Third-party analytics are used to track and measure usage of this website so that we can continue to generate engaging content. These cookies may track details such as how long you spend on the website or pages you visit, which helps us to understand how we can improve the website for you.</p>
          <p>From time to time, we test new features and make subtle changes to the way the website is delivered. When we are still testing new features, these cookies may be used to make sure you receive a consistent experience while on the website while ensuring we understand which optimizations our users appreciate the most.</p>
          <p>As we offer products, it is important for us to understand statistics about how many of the visitors to our website actually make a purchase, and this is the type of data that these cookies will track. This is important to you as it means that we can accurately make business predictions that allow us to monitor our advertising and product costs to make sure the best possible price.</p>
          <p>We also use social media buttons and/or plugins on this website that allow you to connect with your social network in various ways. For these to work, social media sites will set cookies through our website which may be used to enhance your profile on their website or contribute to the data they hold for various purposes outlined in their respective privacy policies.</p>
          <p>If you are still looking for more information, you can contact us through one of our preferred contact methods:</p>
          <p>Email: <strong><a href="mailto:pedal@virbike.com"> pedal@virbike.com</a></strong></p>
          <p>Phone: <strong><a href="tel:+91-9119440258"> +91-9119440258</a></strong></p>
        </div>
      </section>
    </>
  )
}

export default CookiesPolicy;