import React, { useState } from 'react';

const MultipleSelectionCard = ({ onClick, accessoriesData, dataType }) => {
  const [highlightedCards, setHighlightedCards] = useState([]);

  const toggleHighlight = (index) => {
    let updatedHighlightedCards;
    if (highlightedCards.includes(index)) {
      updatedHighlightedCards = highlightedCards.filter((i) => i !== index);
    } else {
      updatedHighlightedCards = [...highlightedCards, index];
    }
    setHighlightedCards(updatedHighlightedCards);

    // Accumulate the selected data and call onClick with the selected items
    const selectedData = updatedHighlightedCards.map((i) => accessoriesData[i]);
    onClick(selectedData);
  };

  return (
    <div className='d-flex g-3 multiple-selection-card'>
      {accessoriesData.map((data, index) => (
        <div
          key={index + 'ad'}
          className='multiple-btn-wrapper'
          style={{
            border: highlightedCards.includes(index) ? '2px solid #A6A6A6' : '2px solid #D3D3D3',
            backgroundColor: highlightedCards.includes(index) ? '#f3f4f6' : ''
          }}
          onClick={() => toggleHighlight(index)}
        >
          <div className='accessories-wrapper'>
            <img src={data.images} className="accessories-img" alt="" width={80} height={80} />
            <div className="accessories-content">
              <div className="accessories-content-title">
                <span>{data.addonName}</span>
                <span>₹{data?.["price"]["originalPrice"]}</span>
              </div>
              <div className="accessories-content-text">
                {data.description}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MultipleSelectionCard;
