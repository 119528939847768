import React, { useState, useEffect } from 'react';
import ReviewStar from '../../components/ui/ReviewStar.js';
import SectionHeading from '../../components/ui/SectionHeading.js';

import {
  TESTIMONIAL_HEADING,
  TESTIMONIAL_SUB_HEADING
} from "../../constants.js";

const testimonialData = [
  {
    img: '/images/customer.png',
    quote: "I’m using V1 from a last year. Best investment for commuting in cantt.",
    author: "Sub. Maj Sandeep Phadtare"
  },
  {
    img: '/images/customer.png',
    quote: "Good design, Nice performance and value for money",
    author: "Milind Kulkarni"
  },
  {
    img: '/images/customer.png',
    quote: "Whether I'm commuting to work or exploring trails on the weekends, my VIR BIKE never disappoints.",
    author: "Pankaj Singh"
  },
  {
    img: '/images/customer.png',
    quote: "Speed is nice I recieved range 45km on throttle and PAS 55km. Recommended to use",
    author: "Uday Kumar"
  },
  {
    img: '/images/customer.png',
    quote: "I love my VIR BIKE! Perfect for leisure rides and proudly designed by sons of the Indian armed forces",
    author: "Capt . Smitha Naidu"
  },
  {
    img: '/images/customer.png',
    quote: "VIR BIKE earns top marks for durability and safety. A quality investment for urban adventurers.",
    author: "Rahul Kushwah"
  },
];

const Testimonial = () => {

  return (
    <section id="testimonials">
      <div className="container-fluid d-flex flex-column justify-content-around h-100">
        <div className="testimonial-content d-flex flex-column align-items-center">
          <SectionHeading
            heading={TESTIMONIAL_HEADING} 
            headingClass={''} 
            subHeading={TESTIMONIAL_SUB_HEADING} 
            subHeadingClass={''} />
        </div>
        <div className="testimonial-container mx-auto">
          <div className="customer-review">
            {testimonialData.map((item, index) => (
              <div className="customer-review-card" key={index+'tm'}>
                  <>
                    <div className="customer-review-card_top d-flex align-items-center">
                      {/* <figure className='customer-img'>
                        <img 
                          src={item.img} 
                          alt={item.author} />
                      </figure> */}
                      <span className='customer-name'>
                        {item.author}
                      </span>
                    </div>
                    <div className="customer-review-card_bottom mt-3">
                      <p className='customer-comment text-dark-gray'>
                        {item.quote}
                      </p>
                    </div>
                  </>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
