import { APIHandler } from "../utils/axiosInstance";

// fetch all addons 
export const fetchAddons = async ()  => {
  try {
    const response = await APIHandler(
      "GET",
      "/addons",
      null,
      {
        "content-type": "application/json; charset=utf-8"
      },
    );
    setAddons(response.data);
  } catch (error) {
    console.log(error.response)
  }
}

export function setAddons(addons) {
  localStorage.setItem('addons', JSON.stringify(addons));
}

export function getAddons() {
  return JSON.parse(localStorage.getItem('addons'));
}
