import React, { useState, useEffect } from 'react';

const ProductCarousel = ({ images = [], ...props }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex(prevIndex => 
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change image every 3 seconds

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [images.length]);

  const goToPreviousImage = () => {
    setCurrentImageIndex(prevIndex => 
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNextImage = () => {
    setCurrentImageIndex(prevIndex => 
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  if (images.length === 0) return <p>No images available</p>;

  return (
    <div className="product-carousel-container">
      <button className="carousel-arrow left-arrow d-none" aria-label="Previous image" onClick={goToPreviousImage}>
        &lt;
      </button>
      {
        props.component === 'details' &&
        <div className="main-image-container" style={{'padding': '5%'}}>
          <img src={images[currentImageIndex]} alt="Product" />
        </div>
      }
      {
        props.component !== 'details' &&
        <div className="main-image-container">
          <img src={images[currentImageIndex]} alt="Product" />
        </div>
      }
      <button className="carousel-arrow right-arrow d-none" aria-label="Next image" onClick={goToNextImage}>
        &gt;
      </button>
    </div>
  );
};

export default ProductCarousel;