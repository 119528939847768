import React, { useEffect, useState } from "react";
import { APIHandler } from "../../utils/axiosInstance";
import { useDispatch } from "react-redux";
import { addItemToCart, removeItemFromCart } from '../../slices/cartSlice';
import { toast } from "react-toastify";

import MultipleSelectionCard from "./MultipleSelectionCard";
import SingleSelectionCard from "./SingleSelectionCard";
import Button from "../../components/ui/Button";
import CartPopup from "../../components/ui/CartPopup";

const INDIVIDUAL_PRODUCT_AMOUNT = {
  "bikeAmount": 0,
  'rangeAmount': 0,
  'addonAmount': 0
}

const ProductDetail = ({product}) => {
  const dispatch = useDispatch();
  const { colorOptions, rangeOption } = product;
  const [isLoaded, setIsLoaded] = useState(false);
  const [addonCompatibleBikes, setAddonCompatibleBikes] = useState([]);
  const [selectedColor, setSelectedColor] = useState();
  const [selectedRange, setSelectedRange] = useState();
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [individualProductTotal, setIndividualProductTotal] = useState(INDIVIDUAL_PRODUCT_AMOUNT);
  const [cartPopupOpen, setCartPopupOpen] = useState(false);
  const [cartProduct, setCartProduct] = useState(null);


  useEffect(() => {
    const fetchAddonCompatibleBikes = async () => {
      const res = await APIHandler(
        "GET",
        `/addons/compatible-bikes/${product._id}`,
        null,
        {
          "content-type": "application/json; charset=utf-8"
        },
      );

      if (res.status !== 200) {
        console.log("No data is present")
      } else {     
        setIsLoaded(true);
        setAddonCompatibleBikes(res.data);
      }
    }
    fetchAddonCompatibleBikes();

    setIndividualProductTotal((prevState) => ({
      ...prevState,
      bikeAmount: product.pricing.originalPrice,
    }));
    
  }, [product._id]);

  const selectedColorOption = (data) => {
    setSelectedColor(data);
  }

  const selectedRangeOption = (data) => {
    setSelectedRange(data.type);
    setIndividualProductTotal((prevState) => ({
      ...prevState,
      rangeAmount: data.price,
    }));
  }

  const selectedAddonsOptions = (data) => {
    setSelectedAddons(data);
    setIndividualProductTotal((prevState) => ({
      ...prevState,
      addonAmount: data.reduce((sum, addon) =>  sum + addon.price.originalPrice, 0),
    }));
  }

  const handleAddToCart = () => {
    dispatch(addItemToCart({
      product,
      addons: Object.keys(selectedAddons).length > 0 ? selectedAddons : [],
      bikeQuantity: 1,
      selectedColorOption: selectedColor,
      selectedRangeOption: selectedRange
    }));
    toast.success(`Product added to cart`);

    setCartProduct(product);
    setCartPopupOpen(true);
    // setTimeout(() => {
    //   setCartPopupOpen(false);
    // }, 3000);
  }

  const isButtonDisabled = !selectedColor || !selectedRange;

  return (
    <>
      <div className="prod-detail-wrapper">
        <div className="skin">
          <div className="skin-header d-flex justify-content-between">
            <span>Pick your Skin</span>
            <span>Included</span>
          </div>
          <div className="skin-type">
            <SingleSelectionCard 
              onClick={selectedColorOption}
              data={colorOptions} 
              dataType="skin"/>
          </div>
        </div>
        <div style={{borderStyle: 'solid', borderWidth: '1px 0px 0px 0px', borderColor: '#0000001A', margin: '2rem 0'}}></div>
        <div className="range">
          <div className="range-header d-flex justify-content-between">
            <span>Pick your Range</span>
            <span>{individualProductTotal.rangeAmount == 0 ? 'Included' : '₹' + individualProductTotal.rangeAmount}</span>
          </div>
          <div className="range-type">
            <SingleSelectionCard 
              onClick={selectedRangeOption}
              data={rangeOption} 
              dataType="range"/>
          </div>
        </div>
        <div style={{borderStyle: 'solid', borderWidth: '1px 0px 0px 0px', borderColor: '#0000001A', margin: '2rem 0'}}></div>
        <div className="accessories">
          <div className="accessories-header d-flex justify-content-between">
            <span>Accessories</span>
          </div>
          <div className="accessories-type">
            {
              isLoaded && 
              addonCompatibleBikes.length > 0 && 
              <MultipleSelectionCard 
                onClick={selectedAddonsOptions}
                accessoriesData={addonCompatibleBikes} 
                dataType="accessories"/>
            }
                        {
              isLoaded && 
              addonCompatibleBikes.length == 0 && 
              <p className="fs-5 px-4">It looks like all accessories are included in your new bike 😎</p>
            }
          </div>
        </div>
      </div>
      <div className="amount-card">
        <div className="amount-card-wrapper">
          <div className="amount-card-total">
            <span>Total</span>
            <span className="mx-3">
              ₹{individualProductTotal.bikeAmount + individualProductTotal.rangeAmount + individualProductTotal.addonAmount}
            </span>
          </div>
          <div className="amount-card-btn">
            <span style={{fontSize: '1rem'}}>* Inclusive of tax</span>
            <Button 
              className={'amount-btn'} 
              onClick={() => handleAddToCart()}
              disabled={isButtonDisabled}>
              Add to cart
            </Button>
          </div>
        </div>
      </div>

      {cartPopupOpen && (
        <CartPopup
          product={cartProduct}
          selectedColorOption={selectedColor}
          total={individualProductTotal.bikeAmount + individualProductTotal.rangeAmount + individualProductTotal.addonAmount}
          isOpen={cartPopupOpen}
          onClose={() => setCartPopupOpen(false)}
        />
      )}
    </>
  )
}

export default ProductDetail;