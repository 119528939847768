import Seo from "../../components/common/Seo";

const TermsAndCondition = () => {
  return (
    <>
      <Seo 
        title="VIR BIKE Terms and Conditions - Understand Your Rights"
        description="Familiarize yourself with the terms and conditions governing the use of VIR BIKE's website and products. Learn about warranties, returns, and legal obligations."
        name="VIR MOBILITY PVT. LTD"
        type="webapp"
        />
      <section id="tnc" className="section">
        <div className="tnc-container container">
          <h1>Terms And Conditions</h1>
          <h2>Privacy Policy and Terms of Use for VIR BIKE</h2>
          <p>Thank you for visiting www.virbike.com. At VIR Mobility Private Limited ("VIR BIKE"), we value your privacy and are committed to protecting your personal information. This Privacy Policy applies solely to information collected via our website and does not cover information collected through other means.</p>
          
          <div className="mt-5">
            <h2 className="mb-4">Privacy of Personal Information</h2>
            <p>VIR BIKE respects your privacy and does not collect personal information without your consent. We do not sell, distribute, or rent personal information (such as email addresses or contact details) shared via our website. Any data submitted through contact forms is secured and used solely for communication purposes.</p>  
          </div>

          <div className="mt-5">
            <h2 className="mb-4">Cookies Policy</h2>
            <p>VIR BIKE may use cookies to understand website traffic and enhance user experience. We do not use cookies for tracking personal information. Session cookies may be used but are not stored on users' computers.</p>  
          </div>

          <div className="mt-5">
            <h2 className="mb-4">Surveys</h2>
            <p>VIR BIKE may conduct online surveys to better understand user preferences. Participation is voluntary, and users can provide feedback at their discretion. Any personal information provided during surveys is given voluntarily and will be treated confidentially.</p>  
          </div>

          <div className="mt-5">
            <h2 className="mb-4">External Links</h2>
            <p>Our website may contain links to third-party websites. VIR BIKE is not responsible for the content or privacy practices of such external sites.</p>  
          </div>

          <div className="mt-5">
            <h2 className="mb-4">Security</h2>
            <p>While we take reasonable measures to safeguard your personal information, VIR BIKE cannot guarantee absolute security due to the nature of internet communication. VIR Bike is not liable for any unauthorized access or security breaches.</p>  
          </div>

          <div className="mt-5">
            <h2 className="mb-4">Social Media Disclaimer</h2>
            <p>Content posted on VIR BIKE's social media accounts (including but not limited to Facebook and Twitter) is for general reference and does not constitute official endorsements or commitments by VIR Bike. The views expressed by followers and third parties are their own, and VIR Bike is not responsible for their opinions. All content is protected by applicable copyright and intellectual property laws.</p>  
          </div>

          <div className="mt-5">
            <h2 className="mb-4">Limitation of Liability</h2>
            <p>VIR BIKE shall not be liable for any indirect, incidental, or consequential damages arising from the use of its website, products, or social media platforms. All legal matters are subject to the exclusive jurisdiction of the courts of [Location].</p>  
          </div>

          <div className="mt-5">
            <h2 className="mb-4">Changes to the Policy</h2>
            <p>VIR BIKE reserves the right to update this Privacy Policy and Terms of Use at any time. The date of the last update will be revised at the top of the document.</p>  
          </div>

          <div className="mt-5">
            <h2 className="mb-4">Copyright</h2>
            <p>All content on this website, including but not limited to text, images, code, and trademarks, is the property of VIR Mobility Private Limited. Unauthorized use or reproduction is strictly prohibited.</p>  
          </div>

          <div className="mt-5">
            <p className='mt-5'>Copyright © VIR Mobility Private Limited, All Rights Reserved.</p>
          </div>
        </div>
      </section>
    </>
  )
}

export default TermsAndCondition;