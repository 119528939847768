
import Seo from '../../components/common/Seo';

const TradeInPolicy = () => {
  return (
    <>
      <Seo 
        title="VIR BIKE Trade-In Policy - Seamless Exchange, Valuable Savings"
        description="Discover VIR BIKE's trade-in policy. Exchange your old bicycle for discounts on new VIR BIKE models. Learn about eligibility, valuation, process, and legal terms."
        name="VIR MOBILITY PVT. LTD"
        type="webapp"
        />
      <section id="privacy-policy" className="section">
        <div className="privacy-policy-container container">
          <h1> Trade-In Policy</h1>
          <p>This Trade-In Policy outlines the terms and conditions for customers exchanging their old bicycles for discounts on VIR BIKE purchases. By participating in the trade-in program, customers agree to the following:</p>

          <div className="polices">
            <ol className="custom-o-list">
              <li className="custom-list">
                Eligibility
                <p>- The trade-in program is available only for bicycles (non-electric or electric).</p>
                <p>- The bicycle must be in the customer's legal possession, with no third-party claims or encumbrances.</p>
                <p>- Proof of ownership (original invoice or a declaration of ownership) must be submitted at the time of trade-in.</p>
              </li>
              <li className="custom-list">
                Valuation
                <p>- The trade-in value will be determined solely by VIR BIKE's external trade-in partner.</p>
                <p>- Valuations are final and non-negotiable.</p>
                <p>- The partner may reject bicycles that do not meet inspection standards (e.g., excessive damage, missing parts).</p>
              </li>
              <li className="custom-list">
                Process
                <p>- Customers must share images and details of the bicycle with VIR BIKE's partner for pre-evaluation.</p>
                <p>- After evaluation, the partner will provide a valuation certificate with the trade-in amount.</p>
                <p>- The trade-in amount will be directly deducted from the final invoice of the new VIR BIKE purchase.</p>
              </li>
              <li className="custom-list">
                Liability and Ownership Transfer
                <p>- VIR BIKE is not liable for disputes or negotiations related to valuation or inspection.</p>
                <p>- Ownership of the traded bicycle transfers to the trade-in partner upon acceptance and handover.</p>
                <p>- Customers must ensure that the traded bicycle is free from theft or other legal issues.</p>
              </li>
              <li className="custom-list">
                Refunds and Cancellations
                <p>- Once the trade-in is accepted and the amount applied, no cancellation or reversal will be permitted.</p>
                <p>- If the VIR BIKE order is canceled after trade-in completion, the traded bicycle cannot be returned.</p>
              </li>
              <li className="custom-list">
                Disclaimers
                <p>- VIR BIKE is not responsible for any post-handover issues, including discrepancies in  valuation or service by the trade-in partner.</p>
                <p>- VIR BIKE reserves the right to modify or terminate the trade-in program without prior notice.</p>
              </li>
              <li className="custom-list">
                Dispute Resolution and Governing Law
                <p>- Any disputes arising from the trade-in transaction will be resolved through arbitration under the Maharashtra Arbitration Act.</p>
                <p>- The program is governed by the laws of India, with legal proceedings subject to the jurisdiction of courts in Pune, Maharashtra.</p>
              </li>



              <li className="custom-list">
                Contact Information
                <p>
                For questions or concerns regarding this Trade-in Policy, please contact us at:
                  Email: <strong><a href="mailto:pedal@virbike.com"> pedal@virbike.com</a></strong>                  
                </p>
                <p>Phone: <strong><a href="tel:+91-9119440258"> +91-9119440258</a></strong></p>
              </li>
            </ol>
          </div>
        </div>
      </section>
    </>
  )
}

export default TradeInPolicy;