import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/ui/Button';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className='nf-container d-flex flex-column justify-content-center align-items-center text-center'>
      <h1 className='nf-container-heading'>404</h1>
      <p className='nf-container-message mb-3'>
        Oops! The page you're looking for doesn't exist.
      </p>
      <Button
        onClick={() => navigate('/')} 
        className="feature-btn mt-4">
          Go Back Home
      </Button>
    </div>
  );
}

export default NotFound;