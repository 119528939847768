import AboutSection from "./AboutSection";
import FAQ from "../../components/ui/Faq.js";
import FeatureSection from "./FeatureSection.js";
import Hero from "./Hero.js"
import ProductSection from "./ProductSection.js";
import Testimonial from "./Testimonial.js";
import WhySection from "./WhySection.js";
import V3banner from "./V3banner.js";
import Seo from "../../components/common/Seo.js";

const Home = () => {
  return (
    <>
      <Seo 
        title="VIR BIKE - India's most innovative electric cycle"
        description="Explore VIR BIKE's range of high-performance electric cycles, featuring unisex designs, durable aluminum alloy frames, 250W BLDC motors, and advanced pedal-assist technology. Perfect for all ages and lifestyles."
        name="VIR MOBILITY PVT. LTD"
        type="webapp"
        />
      <Hero />
      <AboutSection />
      <FeatureSection />
      <ProductSection />
      <V3banner />
      {/* <WhySection /> */}
      <Testimonial />
      <FAQ />
    </>
  )
}

export default Home;