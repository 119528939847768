import { Link } from "react-router-dom";

const DrivesUs = () => {
  return (
    <section id="drives-us-section">
      <div id="drivesUs" className="drivesUs">
        <div>
          <figure className="img-container w-100">
            <img loading="lazy" src="/images/drivesUp.jpg" alt="" className="w-100 img-fluid" />
          </figure>
          <div className="drivesUs-caption">
            <h2>Turn your Passion<br/>into Motion</h2>
          </div>
        </div>
        <div className="drivesUs-card">
          <figure className="w-100 d-none d-lg-block">
            <img loading="lazy" src="/images/drivesUp_v3.jpg" alt="" className="w-100 img-fluid" />
          </figure>
          <div className="drivesUs-card-content">
            <h4 className="text-dark-gray">Join the VIR BIKE Journey</h4>
            <p className="text-light-gray">
              Where Your Passion Drives Tomorrow's Adventures
            </p>
            <div className="mt-3 fs-5 fw-bold">
              <Link to={'/product-listing'}>SHOP NOW &#8594;</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DrivesUs;