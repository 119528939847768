import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { APIHandler } from "../../utils/axiosInstance";
import Button from "../../components/ui/Button";
import { indianLocations } from "../../utils/statesAndUTs";
import { clearCart } from '../../slices/cartSlice';
import { amountFormat } from '../../utils/amountFormat.js';

const ORDER_FORM = {
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  EMAIL: 'Email',
  PHONE: 'Phone Number',
  GENDER: 'Gender',
  GSTNO: 'GST Number',
  STREET: 'Street',
  HOUSE_NO: 'House Number',
  CITY: 'City',
  STATE: 'State',
  ZIPCODE: 'Zipcode',
  LANDMARK: 'Landmark',  
};

const INITIAL_FORM_VALUE = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  gender: '',
  gstNo: '',
  street: '',
  houseNo: '',
  city: '',
  state: '',
  zipcode: '',
  landMark: '',
};

const InputField = ({ label, name, type = 'text', value, onChange, error }) => (
  <div className="input_box">
    <label className="input_label">{label}</label>
    <input
      type={type}
      name={name}
      className="input_field"
      value={value}
      onChange={onChange}
      required
      aria-invalid={!!error}
    />
    {error && <span className='error'>{error}</span>}
  </div>
);

const SelectField = ({ label, name, options, value, onChange, error }) => (
  <div className="input_box">
    <label className="input_label">{label}</label>
    <select
      name={name}
      className="input_field"
      value={value}
      onChange={onChange}
      required
      aria-invalid={!!error}
    >
      <option value="">Select</option>
      {options.map((option, index) => (
        <option value={option} key={index}>{option}</option>
      ))}
    </select>
    {error && <span className='error'>{error}</span>}
  </div>
);

const OrderForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(INITIAL_FORM_VALUE);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const dispatch = useDispatch();

  const cartItems = JSON.parse(localStorage.getItem('cartState'));

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  }, []);

  const validateForm = useCallback((data) => {
    const errors = {};
  
    // Validation rules
    const rules = {
      firstName: {
        required: true,
        pattern: /^[A-Za-z\s]+$/,
        message: 'First Name is required and must contain only letters',
      },
      lastName: {
        required: true,
        pattern: /^[A-Za-z\s]+$/,
        message: 'Last Name is required and must contain only letters',
      },
      email: {
        required: true,
        pattern: /\S+@\S+\.\S+/,
        message: 'Email is required and must be a valid email address',
      },
      phone: {
        required: true,
        pattern: /^\d{10}$/,
        message: 'Phone number is required and must be 10 digits',
      },
      gender: {
        required: false,
        pattern: /^(Male|Female|Other)$/,
        message: 'Gender is required and must be Male, Female, or Other',
      },
      gstNo: {
        required: false,
        pattern: /^[0-9]{15}$/,
        message: 'GST Number must be 15 digits if provided',
      },
      street: {
        required: true,
        message: 'Street is required',
      },
      houseNo: {
        required: true,
        message: 'House Number is required',
      },
      city: {
        required: true,
        pattern: /^[A-Za-z\s]+$/,
        message: 'City is required and must contain only letters',
      },
      state: {
        required: true,
        message: 'State is required',
      },
      zipcode: {
        required: true,
        pattern: /^\d{5,6}$/,
        message: 'Zipcode is required and must be 5 or 6 digits',
      },
      landMark: {
        required: false,
      },
    };
  
    // Iterate over form data and validate each field
    for (const field in rules) {
      const rule = rules[field];
      const value = data[field]?.trim() || '';
  
      if (rule.required && !value) {
        errors[field] = rule.message || `${field} is required`;
      } else if (rule.pattern && value && !rule.pattern.test(value)) {
        errors[field] = rule.message || `Invalid ${field}`;
      }
    }
  
    return errors;
  }, []);  

  const handleFormSubmit = useCallback(async (e) => {
    e.preventDefault();

    const totalAmt = JSON.parse(localStorage.getItem('cartState')).totalAmount;
    
    // Validate form
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      setErrors({});
      setSuccess(null);

      const orderObj = {
        cartId: localStorage.getItem('cartId'),
        formData: formData,
        amount: totalAmt
      }

      try {
        const { data } = await APIHandler(
          "POST",
          "/order",
          JSON.stringify(orderObj),
          {
            "content-type": "application/json; charset=utf-8"
          },
        );
        setSuccess('Form submitted successfully!');
        setFormData(INITIAL_FORM_VALUE);
        const receipt = data.receipt;
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          amount: data.amount, // Amount in paise
          currency: data.currency,
          name: 'VIR Bike',
          description: 'Payment for your selected bike',
          order_id: data.id, // Order ID created by Razorpay
          handler: async function (response) {
            const { data } = await APIHandler(
              "POST",
              "/order/verify-payment",
              JSON.stringify({
                order_id: response.razorpay_order_id,
                payment_id: response.razorpay_payment_id,
                signature: response.razorpay_signature,
                receipt: receipt
              }),
              {
                "content-type": "application/json; charset=utf-8"
              },
            );
            if (data.success) {
              alert('Payment Successful!');
            } else {
              alert('Payment Verification Failed!');
            }
          },
          prefill: {
            name: `${formData.firstName} ${formData.lastName}`,
            email: formData.email,
            contact: formData.contact,
          },
          theme: {
            color: '#093125',
          },
        };
  
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();

      } catch (error) {
        setErrors({ form: 'An error occurred while submitting the form.' });
      } finally {
        setLoading(false);
        dispatch(clearCart());
        navigate('/order-received');
      }
    } else {
      setErrors(errors);
      setLoading(false);
    }
  }, [formData, validateForm]);

  return (
    <>
      <div>
        <div className='order-details'>
          <div className="order-details-top text-center d-flex flex-column gap-3 py-5">
            <p className='fs-5'>Total amount</p>
            <h2>₹{amountFormat(cartItems.totalAmount)}</h2>
            <div className='fs-5'>
              <span>
                <img loading="lazy" src="/images/icons/padlock.png" alt="secure payment" width={12} height={12}/>
              </span>
              <span className='px-2'>Secure Payment</span>
            </div>
            <hr />
          </div>
          <div className='order-details-bottom d-flex flex-column'>
            <p className='fs-5 mb-5 text-light'>Order Summary</p>
            {
              cartItems && cartItems.items.map(item => (
                <div className='mb-4'>
                  <div className='d-flex justify-content-between mb-3 fs-4 fw-bold text-dark-gray'>
                    <p>{item.product.productName} ({item.selectedColorOption}) x {item.bikeQuantity}</p>
                    <p>₹{amountFormat(item.product.pricing.originalPrice * item.bikeQuantity)}</p>
                  </div>
                  {
                    item.addons.length > 0 && item.addons.map(addon => (
                      <div className='d-flex justify-content-between mb-2 fs-5 text-light-gray'>
                        <p>{addon.addonName} x {addon.quantity}</p>
                        <p>₹{amountFormat(addon.originalPrice * addon.quantity)}</p>
                      </div>
                    ))
                  }                
                </div>
              ))
            }
            <hr />
          </div>
          <div className='d-flex justify-content-between fs-4 mb-3 text-light-gray'>
            <p>Subtotal</p>
            <p className='text-dark-gray fw-bold'>₹{amountFormat(cartItems.totalAmount)}</p>
          </div>
          <div className='d-flex justify-content-between fs-4 mb-3 text-light-gray'>
            <p>Shipping</p>
            <p className='text-dark-gray fw-bold'>Free</p>
          </div>
          <div className='d-flex justify-content-between fs-4 text-light-gray'>
            <p>Tax</p>
            <p className='text-dark-gray fw-bold'>Inc.</p>
          </div>
          <hr />
          <div className='d-flex justify-content-between fs-4 fw-bold'>
            <p>Total</p>
            <p>₹{amountFormat(cartItems.totalAmount)}</p>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <div className="form-wrap">
          <h2>BILLING & SHIPPING ADDRESS</h2>

          <form onSubmit={handleFormSubmit} noValidate>
            <div className='order-field-wrap'>
              <InputField
                label={ORDER_FORM.FIRST_NAME}
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                error={errors.firstName}
              />            
              <InputField
                label={ORDER_FORM.LAST_NAME}
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                error={errors.lastName}
              />
            </div>

            <div className='order-field-wrap'>
              <InputField
                label={ORDER_FORM.EMAIL}
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={errors.email}
              />            
              <InputField
                label={ORDER_FORM.PHONE}
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                error={errors.phone}
              />
            </div>
            
            <div className='order-field-wrap'>
              <SelectField
                label={ORDER_FORM.GENDER}
                name="gender"
                options={['Male', 'Female', 'Other']}
                value={formData.gender}
                onChange={handleChange}
                error={errors.gender}
              />            
              <InputField
                label={ORDER_FORM.GSTNO}
                name="gstNo"
                value={formData.gstNo}
                onChange={handleChange}
                error={errors.gstNo}
              />
            </div>
            
            <div className='order-field-wrap'>
              <InputField
                label={ORDER_FORM.HOUSE_NO}
                name="houseNo"
                value={formData.houseNo}
                onChange={handleChange}
                error={errors.houseNo}
              />
              <InputField
                label={ORDER_FORM.STREET}
                name="street"
                value={formData.street}
                onChange={handleChange}
                error={errors.street}
              />
            </div>
            
            <div className='order-field-wrap'>
              <InputField
                label={ORDER_FORM.CITY}
                name="city"
                value={formData.city}
                onChange={handleChange}
                error={errors.city}
              />            
              <SelectField
                label={ORDER_FORM.STATE}
                name="state"
                options={indianLocations}
                value={formData.state}
                onChange={handleChange}
                error={errors.state}
              />
            </div>

            <div className='order-field-wrap'>
              <InputField
                label={ORDER_FORM.ZIPCODE}
                name="zipcode"
                type="text"
                value={formData.zipcode}
                onChange={handleChange}
                error={errors.zipcode}
              />            
              <InputField
                label={ORDER_FORM.LANDMARK}
                name="landMark"
                value={formData.landMark}
                onChange={handleChange}
                error={errors.landMark}
              />
            </div>

            <div className='order-field-wrap mt-5'>
              <div className='razorpay'>
                <span>Powered by</span>
                <img loading="lazy" src="/images/icons/razorpay.png" className="img-fluid" alt="razorpay" />
              </div>
              <Button type="submit" className={'paynow-btn'} disabled={loading}>
                {loading ? 'processing...' : 'Make Payment'}
              </Button>
            </div>
          </form>

          {success && <div aria-live="polite" className="success-message">{success}</div>}
          {errors.form && <div aria-live="polite" className="error-message">{errors.form}</div>}
        </div>
      </div>
    </>
  );
}

export default OrderForm;