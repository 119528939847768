import ContactDetails from "./ContactDetails.js";
import ContactForm from "./ContactForm.js";
import SectionHeading from "../../components/ui/SectionHeading.js";

import {
  CONTACT_HEADING,
  CONTACT_SUB_HEADING,
  CONTACT_SECTION_DESCRIPTION
} from "../../constants.js";
import Seo from '../../components/common/Seo.js';

const Contact = () => {
  return (
    <>
      <Seo 
        title="Contact VIR BIKE - We're Here to Help"
        description="Have questions or need support? Contact VIR BIKE for any inquiries about our products, services, or community. We're here to assist you."
        name="VIR MOBILITY PVT. LTD"
        type="webapp"
        />
      <section id="contact" className="section">
        <div className="contact-container container d-flex flex-column">
        <SectionHeading 
          heading={CONTACT_HEADING} 
          headingClass={''} 
          subHeading={CONTACT_SUB_HEADING} 
          subHeadingClass={''} />
          <div className="contact-description">
            {CONTACT_SECTION_DESCRIPTION}
          </div>
          <div className="form-and-details">
            <ContactForm />
            <ContactDetails />
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact;