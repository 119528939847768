const SectionHeading = ({heading, headingClass, subHeading, subHeadingClass}) => {
  return (
    <>
      <div className={`heading ${headingClass}`}>
        {heading}
      </div>
      <div className={`sub-heading ${subHeadingClass}`}>
        <h2>
          {subHeading}
        </h2>
      </div>
    </>
  )
}

export default SectionHeading;