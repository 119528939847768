// cartSlice.js
import { createSlice } from '@reduxjs/toolkit';

// Utility functions
const calculateItemTotal = (item) => {
  const bikeBasePrice = item.product.rangeOption
    .find(range => range.battery.type === item.selectedRangeOption)?.price || item.product.pricing.originalPrice;
  const addonsTotal = item.addons.reduce((sum, addon) => sum + addon.originalPrice * addon.quantity, 0);
  return (bikeBasePrice * item.bikeQuantity) + addonsTotal;
};

const calculateCartTotal = (items) => items.reduce((sum, item) => sum + item.totalAmount, 0);

const getItemIndex = (state, product, selectedColorOption, selectedRangeOption) => 
  state.items.findIndex(item => 
    item.product._id === product._id &&
    item.selectedColorOption === selectedColorOption &&
    item.selectedRangeOption === selectedRangeOption
  );

const createAddonMap = (addons) => {
  return addons.reduce((acc, addon) => {
    if (!addon || !addon.price || typeof addon.price.originalPrice === 'undefined') {
      console.warn('Addon or price information is missing:', addon);
      return acc;
    }
    if (!acc[addon._id]) {
      acc[addon._id] = { quantity: 0, originalPrice: addon.price.originalPrice, addonName: addon.addonName };
    }
    acc[addon._id].quantity++;
    return acc;
  }, {});
};

const mergeAddons = (existingAddons, newAddons) => {
  newAddons.forEach((addon) => {
    const existingAddon = existingAddons.find(a => a.id === addon.id);
    if (existingAddon) {
      existingAddon.quantity += addon.quantity;
    } else {
      existingAddons.push(addon);
    }
  });
};

// Load initial state from localStorage
const loadStateFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('cartState');
    if (serializedState) {
      return JSON.parse(serializedState);
    }
  } catch (err) {
    console.error('Failed to load cart state from localStorage:', err);
  }
  return { items: [], totalAmount: 0 }; // Return default state if localStorage is empty or error occurs
};

// Save state to localStorage
const saveStateToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('cartState', serializedState);
  } catch (err) {
    console.error('Failed to save cart state to localStorage:', err);
  }
};

const initialState = loadStateFromLocalStorage();

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItemToCart: (state, action) => {
      const { product, bikeQuantity, selectedColorOption, selectedRangeOption, addons = [] } = action.payload;

      const addonMap = createAddonMap(addons);
      const addonObj = Object.keys(addonMap).map(key => ({
        id: key,
        quantity: addonMap[key].quantity,
        originalPrice: addonMap[key].originalPrice,
        addonName: addonMap[key].addonName
      }));

      const itemIndex = getItemIndex(state, product, selectedColorOption, selectedRangeOption);

      if (itemIndex >= 0) {
        const existingBikeItem = state.items[itemIndex];
        existingBikeItem.bikeQuantity += bikeQuantity;
        mergeAddons(existingBikeItem.addons, addonObj);
        existingBikeItem.totalAmount = calculateItemTotal(existingBikeItem);
      } else {
        const newItem = {
          product,
          addons: addonObj,
          bikeQuantity,
          totalAmount: calculateItemTotal({
            product,
            bikeQuantity,
            selectedColorOption,
            selectedRangeOption,
            addons: addonObj
          }),
          selectedColorOption,
          selectedRangeOption
        };
        state.items.push(newItem);
      }

      state.totalAmount = calculateCartTotal(state.items);
      saveStateToLocalStorage(state);
    },

    removeItemFromCart: (state, action) => {
      const { product, selectedColorOption, selectedRangeOption, remove, addonId } = action.payload;
      const itemIndex = getItemIndex(state, product, selectedColorOption, selectedRangeOption);

      if (itemIndex >= 0) {
        const item = state.items[itemIndex];

        if (remove === 'product') {
          state.items.splice(itemIndex, 1);
        } else if (remove === 'addon' && addonId) {
          item.addons = item.addons.filter(addon => addon.id !== addonId);
        }

        if (state.items[itemIndex]) {
          item.totalAmount = calculateItemTotal(item);
        }

        state.totalAmount = calculateCartTotal(state.items);
        saveStateToLocalStorage(state);
      }
    },

    increaseProductQuantity: (state, action) => {
      const { product, selectedColorOption, selectedRangeOption, dataType, addonId } = action.payload;
      const itemIndex = getItemIndex(state, product, selectedColorOption, selectedRangeOption);

      if (itemIndex >= 0) {
        const item = state.items[itemIndex];

        if (dataType === 'product') {
          item.bikeQuantity += 1;
        } else if (dataType === 'addon') {
          const addon = item.addons.find(a => a.id === addonId);
          if (addon) addon.quantity += 1;
        }

        item.totalAmount = calculateItemTotal(item);
        state.totalAmount = calculateCartTotal(state.items);
        saveStateToLocalStorage(state);
      }
    },

    decreaseProductQuantity: (state, action) => {
      const { product, selectedColorOption, selectedRangeOption, dataType, addonId } = action.payload;
      const itemIndex = getItemIndex(state, product, selectedColorOption, selectedRangeOption);

      if (itemIndex >= 0) {
        const item = state.items[itemIndex];

        if (dataType === 'product') {
          if (item.bikeQuantity > 1) {
            item.bikeQuantity -= 1;
          } else {
            state.items.splice(itemIndex, 1);
          }
        } else if (dataType === 'addon') {
          const addon = item.addons.find(a => a.id === addonId);
          if (addon && addon.quantity > 1) {
            addon.quantity -= 1;
          } else {
            item.addons = item.addons.filter(a => a.id !== addonId);
          }
        }

        if (state.items[itemIndex]) {
          item.totalAmount = calculateItemTotal(item);
        }

        state.totalAmount = calculateCartTotal(state.items);
        saveStateToLocalStorage(state);
      }
    },

    clearCart: (state) => {
      state.items = [];
      state.totalAmount = 0;
      saveStateToLocalStorage(state);
    }
  }
});

export const {
  addItemToCart,
  removeItemFromCart,
  increaseProductQuantity,
  decreaseProductQuantity,
  clearCart
} = cartSlice.actions;

export default cartSlice.reducer;
