import OrderForm from "./OrderForm.js";

const Order = () => {
  return (
    <section id="order">
      <div className="order-container container">
        <div className="order-form">
          <OrderForm />
        </div>
      </div>
    </section>
  )
}

export default Order;