import React, { useEffect } from "react";
import Button from "../../components/ui/Button.js";
import SectionHeading from "../../components/ui/SectionHeading.js";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../services/productAPIHandler.js";

import {
  PRODUCT_SECTION_HEADING,
  PRODUCT_SECTION_SUB_HEADING,
  BOOK_NOW
} from "../../constants.js";

const ProductSection = () => {
  const { products } = useSelector((state) => state.products);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProducts());
  }, []);

  return (
    <section id="product-section" className="section">
      <div className="container-fluid product-container">
        <SectionHeading
          heading={PRODUCT_SECTION_HEADING} 
          headingClass={''} 
          subHeading={PRODUCT_SECTION_SUB_HEADING} 
          subHeadingClass={''} />
          <div className="product-wrap">
            <figure>
              <img loading="lazy" src="/images/poster_v2.png" className="product-product-img" alt="The Image" />
            </figure>
            <figure>
              <img loading="lazy" src="/images/poster_v3.png" className="product-product-img" alt="The Image" />
            </figure>
            <figure>
              <img loading="lazy" src="/images/poster_custom.png" className="product-product-img" alt="The Image" />
            </figure>
            {/* {
              products.map(item => {
                return (
                  <div key={item._id+'pd'} className="product-card">
                    <div className="product-section-img">
                      <figure>
                        <img src="/images/product/greenBike.png" className="product-product-img" alt="The Image" />
                      </figure>
                    </div>
                    <div className="product-info">
                      <div className="product-title d-flex">
                          <p className="fs-14">Brand: <strong class="text-title">{item.productName}</strong></p>
                      </div>
                      <div className="product-title d-flex flex-wrap justify-content-between align-items-center mt-4">
                        <h3 className="mb-0 fw-semibold">
                          {item.productName}
                        </h3>
                        <h3 className="mb-0 fw-bold">
                          ₹{item.pricing.originalPrice}
                        </h3>
                      </div>
                      <div className="product-booking mt-4">
                        <Button className={'product-booking-btn'} onClick={() => {}}>
                          {BOOK_NOW}
                        </Button>
                      </div>
                    </div>
                  </div>
                )
              })
            } */}
          </div>
      </div>
    </section>
  )
}

export default ProductSection;