import { APIHandler } from "../utils/axiosInstance";
import { getProduct } from "../slices/productSlice";

// fetch all products 
export const fetchProducts = () => async (dispatch) => {
  try {
    const response = await APIHandler(
      "GET",
      "/products",
      null,
      {
        "content-type": "application/json; charset=utf-8"
      },
    );

    dispatch(getProduct(response.data));
  } catch (error) {
    console.log(error.response)
  }
}