import { v4 as uuidv4 } from 'uuid';

export function setSessionId(sessionId) {
  localStorage.setItem('sessionId', sessionId);
}

export function getSessionId() {
  return localStorage.getItem('sessionId');
}

export function initializeSession() {
  if (!getSessionId()) {
    const newSessionId = uuidv4();
    setSessionId(newSessionId);
  }
}
