import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  orders: []
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    placeOrder: (state, action) => {
      const { cartItems, totalAmount } = action.payload;
      state.orders.push({
        id: new Date().toISOString(),
        items: cartItems,
        totalAmount,
        status: 'Pending'
      });
    },

    updateOrderStatus: (state, action) => {
      const { orderId, status } = action.payload;
      const order = state.orders.find(order => order.id === orderId);
      if (order) {
        order.status = status;
      }
    }
  }
});

export const { placeOrder, updateOrderStatus } = orderSlice.actions;
export default orderSlice.reducer;
