import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ORDER_NOW } from '../constants';

const NAVIGATION_ITEMS = [
  {
    id: 1,
    title : 'Our Products',
    path: '/product-listing'
  },
  {
    id: 2,
    title : 'About Us',
    path: '/about-us'
  },
  {
    id: 3,
    title : 'Vir Community',
    path: '/vir-community'
  },
  {
    id: 4,
    title : 'Contact Us',
    path: '/contact-us'
  }
]

const Header = ({ onHeightChange }) => {
  const [expanded, setExpanded] = useState(false);
  const headerRef = useRef(null);
  const { items } = useSelector(state => state.cart);

  const toggleNavbar = () => {
    setExpanded(prevState => !prevState);
  };

  const handleLinkClick = () => {
    if (expanded) {
      toggleNavbar();
    }
  };

  useEffect(() => {
    if (headerRef.current) {
      const height = headerRef.current.offsetHeight;
      onHeightChange(height);
    }
  }, [onHeightChange]);

  return (
    <nav ref={headerRef} className="navbar navbar-expand-lg py-3 navbar-dark fixed-top bg-dark" data-bs-theme="dark">
      <div className="container-fluid navbar-container">
        <Link to="/" className="navbar-brand">
          <img src="/logo.png" alt="" />
        </Link>

        <div className="cart-and-toggler-container ms-auto d-block d-lg-none px-5">
          <nav className='navbar-nav_actions'>
            <div className="navbar-nav_action">
              <Link to="/cart">
                <img src="/images/icons/cart.png" alt="" width={24} height={24} />
                <div className="cart-length">
                  <h6>{items?.length}</h6>
                </div>
              </Link>
            </div>
          </nav>
        </div>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleNavbar}>
            <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`collapse navbar-collapse justify-content-center ${expanded ? 'show' : ''}`} id="navbarNav">
          <ul className="navbar-nav d-flex justify-content-center mx-auto">
              {
                NAVIGATION_ITEMS.map(item => (
                  <nav className='navbar-nav_actions' key={item.id}>
                    <div className="navbar-nav_action">
                      <Link to={item.path} onClick={handleLinkClick}>
                        <span>{item.title}</span>
                      </Link>
                    </div>
                  </nav>
                ))
              }
            </ul>
            <div className="cart-and-toggler-container align-left d-none d-lg-block">
              <nav className='navbar-nav_actions'>
                <div className="navbar-nav_action">
                  <Link to="/cart">
                    <img src="/images/icons/cart.png" alt="" width={24} height={24} />
                    <div className="cart-length">
                      <h6>{items?.length}</h6>
                    </div>
                  </Link>
                </div>
              </nav>
            </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;