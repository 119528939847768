import SectionHeading from "../../components/ui/SectionHeading.js";
import {
  LEADERSHIP_SECTION_HEADING,
  LEADERSHIP_SUB_SECTION_HEADING
} from "../../constants.js";


const Leadership = () => {
  return ( 
    <section id="leadership-section">
      <div className="container leadership-container">
        <div className="leadership-container-content">
          <SectionHeading 
              heading={LEADERSHIP_SECTION_HEADING} 
              headingClass={''} 
              subHeading={LEADERSHIP_SUB_SECTION_HEADING} 
              subHeadingClass={''} />
          <div 
            className="leadership-container-content_img d-flex flex-column flex-lg-row">
            <div>
              <figure>
                <img loading="lazy"
                src="/images/founder/founder.jpg" alt="Ravi Kumar" />
                <figcaption className="mt-3 text-center founder-details">
                  <span>
                    <span>
                      <span className="fw-bold founder-details-name">
                        <a href="https://www.linkedin.com/in/raviudchalo/" target="_blank" rel="noopener noreferrer">Ravi Kumar </a>
                      </span> 
                      <span className="founder-details-post">(Co-Founder and COO), </span>
                    </span>
                    <span>
                      <span className="fw-bold founder-details-name">
                        <a href="https://www.linkedin.com/in/rahul-gurung-11b59b1b/" target="_blank" rel="noopener noreferrer">Rahul Gurung </a>
                      </span> 
                      <span className="founder-details-post">(Co-Founder and CEO), </span>
                    </span>
                    <span>
                      <span className="fw-bold founder-details-name">
                        <a href="https://www.linkedin.com/in/sahil-uttekar-909b861a0/" target="_blank" rel="noopener noreferrer">Sahil Uttekar </a>
                      </span> 
                      <span className="founder-details-post">(Co-Founder and CTO)</span>
                    </span>
                  </span>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Leadership;