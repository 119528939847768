import React, { useState } from 'react';

const SingleSelectionCard = ({onClick, data, dataType}) => {
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (index) => {
    if (selectedCard === index) {
      setSelectedCard(null);
    } else {
      setSelectedCard(index);
    }
  };

  return (
    <div className='d-flex g-3 single-selection-card'>
      {data.map((option, index) => (
        <div
          key={index+dataType}
          className='btn-wrapper text-center'
          style={{
            border: selectedCard === index ? '2px solid #A6A6A6' : '2px solid #D3D3D3',
            backgroundColor: selectedCard === index ? '#f3f4f6' : '',
          }}
          onClick={() => {
            handleCardClick(index); 
            onClick(dataType === 'skin' ? option.color : option?.["battery"]);
          }}
        >
          { 
            dataType === 'skin' && <button 
            style={{backgroundColor: `${option.colorCode}`}}
            className="pd-round-btn"
            title={option.color}
            ></button>
          }
          {
            dataType === 'range' && <>
              <h5>{option?.["battery"]["type"]}</h5>
            </>
          }
        </div>
      ))}
    </div>
  );
};

export default SingleSelectionCard;
