import Seo from "../../components/common/Seo";
import Newsletter from "../../components/ui/Newsletter";
import Activities from "./Activities";
import HeroCommunity from "./HeroCommunity";
import KeyAchievements from "./KeyAchievements";


const Community = () => {
  return (
    <>
      <Seo 
        title="Join the VIR BIKE Community - Connect, Share, and Ride"
        description="Become a part of the VIR BIKE community and connect with fellow electric cycle enthusiasts. Share your experiences, tips, and stories about riding VIR's durable and stylish electric bikes."
        name="VIR MOBILITY PVT. LTD"
        type="webapp"
        />
      <HeroCommunity />
      <KeyAchievements />
      <Activities />
      <Newsletter heading="Ready to Roll?" />
    </>
  )
}

export default Community;