import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { 
  removeItemFromCart, 
  decreaseProductQuantity,
  increaseProductQuantity,
  clearCart
} from '../../slices/cartSlice';
import Button from '../../components/ui/Button.js';
import { getAddons } from '../../services/addonAPIHandler.js';
import { amountFormat } from '../../utils/amountFormat.js';
import InputWithButton from '../../components/ui/InputWithButton.js';
import { getSessionId } from '../../utils/session.js';
import { APIHandler } from '../../utils/axiosInstance.js';
import { toast } from "react-toastify";

import Seo from '../../components/common/Seo.js';

const INITIAL_COUPON_FORM_VALUE = {
  coupon: ''
}

const Cart = () => {
  const dispatch = useDispatch();
  const [couponFormData, setCouponFormData] = useState(INITIAL_COUPON_FORM_VALUE);
  const [errors, setErrors] = useState({});
  const [cartTotalAmount, setCartTotalAmount] = useState(0);
  const [cartTotalItem, setCartTotalItem] = useState(0);
  const { items } = useSelector(state => state.cart);
  const navigate = useNavigate();
 
  useEffect(() => {
    if (items.length > 0) {
      const cartTotalAmount = items.reduce((sum, item) => sum + item.totalAmount, 0);
      const cartTotalItem = items.reduce((sum, item) => sum + item.bikeQuantity, 0);
      setCartTotalAmount(cartTotalAmount);
      setCartTotalItem(cartTotalItem);
    }
  }, [items])

  const addonsFromLocal = getAddons();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCouponFormData({
      ...couponFormData,
      [name]: value
    });
  };

  const handleDecrement = (product, selectedColorOption, selectedRangeOption, dataType = 'product', addonId = '') => {
    dispatch(decreaseProductQuantity({ product, selectedColorOption, selectedRangeOption, dataType, addonId }));
    toast.success(`Product updated successfully`);
  };

  const handleIncrement = (product, selectedColorOption, selectedRangeOption, dataType = 'product', addonId = '') => {
    dispatch(increaseProductQuantity({ product, selectedColorOption, selectedRangeOption, dataType, addonId }));
    toast.success(`Product updated successfully`);
  };

  const removeCartItem = (product, selectedColorOption, selectedRangeOption, remove = 'product', addonId = '') => {
    dispatch(removeItemFromCart({ product, selectedColorOption, selectedRangeOption, remove, addonId }));
    toast.warning(`Product is removed from cart`);
  }

  const clearCartItems = () => {
    dispatch(clearCart());
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setCouponFormData(INITIAL_COUPON_FORM_VALUE);
    setErrors({});
  }

  const handleClick = () => {
    alert('Button clicked!');
  };

  const createCart = () => {
    const cartArray = [];

    items.map((item) => {
      const { product, addons } = item;

      const processedAddons = addons.map(addon => {
        const { originalPrice, ...rest } = addon;
        return rest;
      });

      const cartObj =  {
        product: product._id,
        quantity: item.bikeQuantity,
        purchasePrice: product.pricing.originalPrice,
        totalPrice: item.totalAmount,
        priceWithTax: item.totalAmount,
        totalTax: 0,
        selectedColorOption: item.selectedColorOption,
        selectedRangeOption: item.selectedRangeOption,
        status: 'Not processed',
        addons: processedAddons,
        coupon: null,
        isCouponApplied: false,
      }
      cartArray.push(cartObj);
    })

    postCart(cartArray)
  }

  const postCart = async (cartArray) => {
    const sessionId = getSessionId();

    const cart = {
      products: cartArray,
      sessionId: sessionId
    }

    try {
      const res = await APIHandler(
        "POST",
        "/cart",
        JSON.stringify(cart),
        {
          "content-type": "application/json; charset=utf-8"
        },
      );

      if(res.data) {
        localStorage.setItem("cartId", res.data._id);
        console.log("res in cart add: ", res.data._id);
      }
    } catch (error) {
      setErrors({ form: 'An error occurred while submitting the form.' });
    }
  }

  return (
    <>
      <Seo 
        title="Your VIR BIKE Cart - Ready to Checkout?"
        description="Finalize your purchase of a VIR BIKE electric cycle. Enjoy secure payment options and get ready to experience premium, durable, and high-performance e-bikes."
        name="VIR MOBILITY PVT. LTD"
        type="webapp"
        />
      <section id="cart">
        <div className="contact-container container d-flex flex-column">
          { items.length === 0 ? (
            <div className="empty-cart d-flex flex-column gap-4">
                <figure>
                  <img loading="lazy" src="/images/banner_empty_cart.jpg" className="product-product-img mb-5" style={{'borderRadius': '2rem'}} alt="The Image" />
                </figure>
                <h1>Your Cart is Empty</h1>
                <p>It looks like you haven't added anything to your cart yet.</p>
                <Button 
                  onClick={() => navigate('/product-listing')} 
                  className="feature-btn mt-4">
                    Continue Shopping
                </Button>
              </div>
            ) : (
              <>
              <div className="cart-container">
                <div className="cart-items cart-container-bg">
                  <div className='d-flex justify-content-between mb-3'>
                    <h4>Cart Item</h4>
                    <div>
                      <a href="#" className="text-link" 
                        onClick={() => clearCartItems()}>
                        <b>Clear cart</b>
                      </a>
                    </div>
                  </div>
                  {
                    items.map((item, index) => {
                      const selectedColor = item.product.colorOptions.find(color => color.color === item.selectedColorOption);
                      const imageUrl = selectedColor ? selectedColor.image[0] : '';

                      const { product, addons} = item;
                      return (
                        <div className='cart-item-box' key={product._id+index}>
                         <div className="cart-item-title fs-4">
                            <span><b className='text-dark-gray'>{product.productName}</b> <small className='cart-item-title-color'>{item.selectedColorOption}</small></span>
                            <div className="cart-item-price text-right">
                              Total: ₹{amountFormat(item.totalAmount)}
                            </div>
                          </div>
                          <div className="cart-item d-flex">
                            <div className="cart-item-image">
                              {imageUrl ? (
                                <img src={imageUrl} className="accessories-img img-fluid" alt={product.productName} width="150" height="auto" />
                              ) : (
                                <p>No image available</p>
                              )}
                            </div>
                            <div className="cart-item-details d-flex align-items-center">
                              <div className="cart-item-sub">
                                <div>
                                  <div className="quantity">
                                    <button 
                                      className="minus" 
                                      aria-label="Decrease"
                                      onClick={() => handleDecrement(product, item.selectedColorOption, item.selectedRangeOption)}>&minus;</button>
                                      <input type="number" className="input-box" value={item.bikeQuantity} min="1" max="4" />
                                    <button 
                                      className="plus" 
                                      aria-label="Increase" 
                                      onClick={() => handleIncrement(product, item.selectedColorOption, item.selectedRangeOption)}>&#43;</button>
                                  </div>
                                </div>
                                <div className="cart-remove">
                                  <a href="#" className="text-link" 
                                    onClick={() => removeCartItem(product, item.selectedColorOption, item.selectedRangeOption)}>
                                    <img src="/images/icons/delete.png" alt="" width={24} height={24} />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='addons-content-container'>
                            { addons && addons.length > 0 &&
                              addons.map((addon, index) => {
                                const addonsList = addonsFromLocal.filter(afl => afl._id === addon.id)
                                return (
                                  <div  key={index+'addon'} className='addons-wrapper'>
                                    <img src={addonsList[0].images} className="addons-img img-fluid" alt="" width={80} height={80} />
                                    <div className="addons-wrapper-content">
                                      <div className="addons-content-top d-flex justify-content-between text-dark-gray">
                                        <span className='fs-4'>{addonsList[0].addonName}</span>
                                        <span className='fs-4'>₹{amountFormat(addonsList[0]?.["price"]["originalPrice"])}</span>
                                      </div>
                                      <div className="addons-content-bottom d-flex justify-content-between">
                                        <div>
                                          <div className="quantity">
                                            <button 
                                              className="minus" 
                                              aria-label="Decrease"
                                              onClick={() => handleDecrement(product, item.selectedColorOption, item.selectedRangeOption, 'addon', addon.id)}>&minus;</button>
                                              <input type="number" className="input-box" value={addon.quantity} min="1" max="4" />
                                            <button 
                                              className="plus" 
                                              aria-label="Increase" 
                                              onClick={() => handleIncrement(product, item.selectedColorOption, item.selectedRangeOption, 'addon', addon.id)}>&#43;</button>
                                          </div>
                                        </div>
                                        <div className="cart-remove">
                                          <a href="#" className="text-link" 
                                            onClick={() => removeCartItem(product, item.selectedColorOption, item.selectedRangeOption, 'addon', addon.id)}>
                                            <img src="/images/icons/delete.png" alt="" width={24} height={24} />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              } 
                            )}
                          </div>
                        </div>
                      );
                  })}
                </div>                                

                <div className="coupon-and-summary d-flex flex-column">
                  <div className="coupon-container cart-container-bg">
                    <h4>Coupon Code</h4>
                    <hr />
                    <div className="form-wrap">
                      <InputWithButton 
                        placeholder="Apply Promocode" 
                        onButtonClick={handleClick} 
                      />
                    </div>
                  </div>

                  <div className="summary-container cart-container-bg d-flex flex-column">
                    <h4 className='text-dark-gray'>Order Summary</h4>
                    <hr />
                    <div className="summary-container-item d-flex justify-content-between mb-3">
                      <p className='text-light-gray fs-5'>Items</p>
                      <p className='fw-bold fs-4'>{cartTotalItem}</p>
                    </div>
                    <div className="summary-container-subtotal d-flex justify-content-between mb-3">
                      <p className='text-light-gray fs-5'>Sub Total</p>
                      <p className='fw-bold fs-4'>₹ {amountFormat(cartTotalAmount)}</p>
                    </div>
                    <div className="summary-container-delivery d-flex justify-content-between mb-3">
                      <p className='text-light-gray fs-5'>Total GST</p>
                      <p className='fw-bold fs-4'>₹0</p>
                    </div>
                    <div className="summary-container-delivery d-flex justify-content-between">
                      <p className='text-light-gray fs-5'>Delivery</p>
                      <p className='fw-bold fs-4'>FREE</p>
                    </div>
                    <hr />
                    <div className="summary-container-total d-flex justify-content-between">
                      <h4 className='fw-bold'>Total</h4>
                      <h4 className='fw-bold'>₹ {amountFormat(cartTotalAmount)}</h4>
                    </div>
                    <Button 
                      className={'feature-btn mt-4 feature-btn-submit'} 
                      onClick={() => {
                        createCart();
                        navigate('/order');
                      }} >
                        Check Out
                    </Button>
                  </div>
                </div>
              </div>
              </>
            )}
          </div>
      </section>
    </>
  )
}

export default Cart;