import React from "react";
import ProductCarousel from "../ProductDetails/ProductCarousel";

const WEB_BANNER_IMG = [
  '/images/hero_v3.jpg',
  '/images/hero_v2.jpg',
  '/images/hero_v3_2.jpg',
]

const MOBILE_BANNER_IMG = [
  '/images/mobile_hero_v3.png',
  '/images/mobile_hero_v2.png',
  '/images/mobile_hero_v3_2.png',
]

const Hero = () => {
  return (
    <section id="hero" className="d-flex align-items-center">
      <div className="d-none d-md-block container-fluid hero p-0">
        <ProductCarousel images={WEB_BANNER_IMG} />
      </div>
      <div className="d-block d-md-none container-fluid hero p-0">
        <ProductCarousel images={MOBILE_BANNER_IMG} />
      </div>
    </section>
  )
}

export default Hero;