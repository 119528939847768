import React, { useState } from 'react';
import { APIHandler } from "../../utils/axiosInstance";
import Button from "../../components/ui/Button";
import { toast } from "react-toastify";

const HERO_FORM = {
  'NAME': 'Full Name',
  'EMAIL': 'Email',
  'PHONE': 'Phone Number',
  'QUERY': 'Query',
  'MESSAGE': 'Message'
}

const INITIAL_FORM_VALUE = {
  name: '',
  email: '',
  phone: '',
  query: 'General Inquiry',
  message: ''
}

const ContactForm = () => {
  const [formData, setFormData] = useState(INITIAL_FORM_VALUE);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      setErrors({});

      try {
        const res = await APIHandler(
          "POST",
          "/contact",
          formData,
          {
            "content-type": "application/json; charset=utf-8"
          },
        );
        setFormData(INITIAL_FORM_VALUE);
        toast.success(`Form submitted successfully!`);
      } catch (error) {
        setErrors({ form: 'An error occurred while submitting the form.' });
      } finally {
        setLoading(false);
      }
    } else {
      setErrors(errors);
    }
  };

  const validateForm = (data) => {
    const errors = {};
    if (!data.name.trim()) {
      errors.name = 'Name is required';
    }

    if (!/^\d{10}$/.test(data.phone.trim())) {
      errors.phone = 'Phone number must be 10 digits';
    }

    if (!/\S+@\S+\.\S+/.test(data.email.trim())) {
      errors.email = 'Email is invalid';
    }

    if (!data.message.trim()) {
      errors.message = 'Message is required';
    }
    return errors;
  };

  return (
    <div className="hero d-flex justify-content-start">
      <div className="form-wrap">
        <h2>Send a message</h2>

        <form onSubmit={handleFormSubmit}>
          <div className="input_box">
            <label className="input_label">{HERO_FORM.NAME}</label>
            <input
              type="text"
              name="name"
              className="input_field"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {errors.name && <span className='error'>{errors.name}</span>}
          </div>

          <div className="input_box">
            <label className="input_label">{HERO_FORM.PHONE}</label>
            <input
              type="text"
              name="phone"
              className="input_field"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            {errors.phone && <span className='error'>{errors.phone}</span>}
          </div>

          <div className="input_box">
            <label className="input_label">{HERO_FORM.EMAIL}</label>
            <input
              type="text"
              name="email"
              className="input_field"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <span className='error'>{errors.email}</span>}
          </div>

          <div className="input_box">
            <label className="input_label">{HERO_FORM.QUERY}</label>
            <select
              name="query"
              className="input_field"
              value={formData.query}
              onChange={handleChange}
              required
            >
              <option value="General Inquiry">General Inquiry</option>
              <option value="Sales Enquiry">Sales Enquiry</option>
              <option value="Product Queries">Product Queries</option>
              <option value="Bulk Order(B2B)">Bulk Order(B2B)</option>
              <option value="Support">Support</option>
              <option value="Feedback">Feedback</option>
              <option value="Other">Other</option>
            </select>
            {errors.query && <span className='error'>{errors.query}</span>}
          </div>

          <div className="input_box">
            <label className="input_label">{HERO_FORM.MESSAGE}</label>
            <textarea
              name="message"
              className="input_field_textbox"
              value={formData.message}
              onChange={handleChange}
              rows="4"
              required
            />
            {errors.message && <span className='error'>{errors.message}</span>}
          </div>

          <Button type="submit" className={'feature-btn mt-5'} disabled={loading}>
            {loading ? 'Submitting...' : 'Submit'}
          </Button>
        </form>
      </div>
    </div>
  )
}

export default ContactForm;