import React from 'react';

const Button = ({ onClick, children, className, ...props }) => {
  return (
    <button className={`custom-btn ${className}`} onClick={onClick} {...props}>
      {children}
    </button>
  );
};

export default Button;
