
import Seo from '../../components/common/Seo';

const WarrantyAndRefuncdPolicy = () => {
  return (
    <>
      <Seo 
        title="VIR BIKE Warranty & Refund Policy - Quality Guaranteed"
        description="Learn about VIR BIKE's warranty and refund policies, ensuring your electric cycle meets the highest quality standards. We're committed to your satisfaction."
        name="VIR MOBILITY PVT. LTD"
        type="webapp"
        />
      <section id="tnc" className="section">
        <div className="wrp-container container">
          <h1>Warranty And Refund Policy</h1>
          <h2 className="mb-4">Warranty Coverage</h2>
          <p>The following components of VIR BIKE products are covered under our warranty:</p>

          <div className="polices mt-5">
            <ol className="custom-o-list">
              <li className="c-list">
                Motors - 1 year warranty
              </li>
              <li className="c-list">
                Battery Packs -  2 year warranty
              </li>
              <li className="c-list">
                Charger - 1 year warranty
              </li>
              <li className="c-list">
                Controller - 1 year warranty
              </li>
              <li className="c-list">
                Frame - Lifetime warranty
              </li>
              <li className="c-list">
                Other E-parts - 1 year warranty
              </li>
            </ol>
          </div>
          <p>This warranty covers parts only and excludes shipping and labor costs associated with repairs.</p>
          <p>Warranty parts are shipped only within India. The warranty does not cover products used for commercial purposes or specialty setups with additional loads, including but not limited to pedicabs, bike rentals, delivery vehicles, cargo bikes, and tandem bikes. VIR Bike does not provide installation or customization support for such specialty setups, and installation or use of products in such a manner voids the warranty.</p>

          <div className="wrp-sub-terms">
            <h2>Warranty Claims</h2>
            <p>In the event of a problem, customers must provide documentation, including photos and/or videos, along with the serial identification number of the defective part. VIR Bike will attempt to troubleshoot the issue remotely. If necessary, customers may need to return the defective part for further inspection. If a component is deemed defective or damaged through no fault of the customer, a replacement part will be issued.</p>
          </div>

          <div className="wrp-sub-terms">
            <h2>Exclusions from Warranty</h2>
            <p>VIR BIKE will not replace any part(s) without first receiving and assessing the necessary documentation (photos, videos, or testing of the damaged part). Warranty does not cover:</p>
            <p>- Wear and tear</p>
            <p>- Water damage</p>
            <p>- Collisions, crashes, accidents, or falls</p>
            <p>- Reprogramming of parts</p>
            <p>- Blown controllers, fried motor coils, or worn gears</p>
            <p>- Customer modifications or alterations</p>
            <p>Once any product has been cut, spliced, reprogrammed, altered, or modified in any way, the warranty is void. Additionally, any use of third-party products, such as batteries purchased from other sellers, voids the warranty due to potential compatibility issues.</p>
            <p>The warranty does not cover products used for stunts, competitions, jumping, acrobatics, BMX, dirt biking, or similar activities. VIR BIKE products are not designed or intended for such uses.</p>
          </div>

          <div className="wrp-sub-terms">
            <h2>Battery Warranty Exclusions</h2>
            <p>Battery warranty does not cover:</p>
            <p>- Power surges</p>
            <p>- Use of improper chargers</p>
            <p>- Normal wear and tear</p>
            <p>- Water damage</p>
            <p>- Modified, altered, or improper connections</p>
          </div>

          <div className="wrp-sub-terms">
            <h2>Warranty Limitations</h2>
            <p>This warranty applies exclusively to the original purchaser of VIR BIKE products and is valid only within the geographical boundaries of the Republic of India. VIR Bike reserves the right to modify the warranty policy at its sole discretion without prior notice.</p>
            <p>In the event of any dispute, the courts of Pune, Maharashtra shall have exclusive jurisdiction. VIR BIKE is not liable for any unauthorized service or use of unauthorized parts, nor for any direct, indirect, or consequential damages, including but not limited to personal injury, property damage, or economic losses, arising from the use of its products.</p>
          </div>

          <div className="wrp-sub-terms">
            <h2>Refund Policy</h2>
            <p>Customer satisfaction is our priority. In cases where dissatisfaction is genuine and proven, VIR BIKE will initiate a refund according to our policies, following an investigation by the appropriate team.</p>
          </div>

          <div className="wrp-sub-terms">
            <h2>Cancellation Policy</h2>
            <p>- Orders may be canceled within 24 hours of placement. Free cancellation is available unless the product has been shipped.</p>
            <p>- If the order status is marked as "shipped" or "out for delivery," a cancellation fee will be charged before processing the refund.</p>
            <p>- Cancellation after dispatch or delivery is not accepted.</p>
          </div>

          <div className="wrp-sub-terms">
            <h2>Return Policy</h2>
            <p>Once the product has been delivered, it cannot be returned or canceled, and no refund will be provided. VIR BIKE is not responsible for any damages incurred during transit.</p>
          </div>

          <div className="wrp-sub-terms">
            <h2>Refund Process</h2>
            <p>In the event of a valid cancellation, the applicable cancellation fee will be deducted, and the remaining amount will be refunded to the original payment source within 7-15 business days, subject to RBI guidelines.</p>
          </div>

          <div className="wrp-sub-terms">
            <h2>Cancellation fees are as follows:</h2>
            <p>- Cancellation within 24 hours (if the order has not been processed) - No fee.</p>
            <p>- If the product has been shipped - 10% of the total order value.</p>
            <p>- If the product is out for delivery - 30% of the total order value.</p>

            <p>Changes to cancellation and refund policies may occur without prior notice.</p>
            <p>In case of a technical issue with the product within 24 hours of delivery, VIR BIKE will replace the faulty part or product.</p>
            <p><b>Note:</b> VIR BIKE cannot replace products due to concerns over height, color, or other non-technical reasons.</p>
          </div>

          <div className="wrp-sub-terms">
            <h2>Assembly and Delivery Policy</h2>
            <p>Customers must provide an accurate delivery address and contact details. Delivery can be expected within 5-12 days of order confirmation. In the case of delivery failure due to incorrect details provided by the customer, any additional re-delivery costs will be borne by the customer.</p>
            <p>If the product cannot be delivered due to reasons beyond VIR BIKE's control, the amount will be refunded to the original source of payment within 90 business days.</p>
          </div>
          
          <div className="wrp-sub-terms">
            <h2>Contact Information</h2>
            <p>You will be required to provide a valid phone number and email address when placing an order. By registering your contact details with us, you consent to being contacted for order and delivery updates. If you wish to opt out of promotional communication, you may do so at any time.</p>
          </div>

          <div className="wrp-sub-terms">
            <h2>For further inquiries, please contact</h2>
              <p>Email: <strong><a href="mailto:pedal@virbike.com"> pedal@virbike.com</a></strong></p>
              <p>Phone: <strong><a href="tel:+91-9119440258"> +91-9119440258</a></strong></p>
          </div>

          <div>
            <p>All terms and conditions are subject to change at the discretion of VIR BIKE without prior notice.</p>
          </div>
        </div>
      </section>
    </>
  )
}

export default WarrantyAndRefuncdPolicy;