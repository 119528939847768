import axios from "axios";

const instance = axios.create({
  baseURL:
    process.env.REACT_APP_ENVIRONMENT === "development"
      ? process.env.REACT_APP_DEV_API_BASE_URL
      : process.env.REACT_APP_PROD_API_BASE_URL,
  withCredentials: false,
});

export const APIHandler = (
  method,
  url,
  data = null,
  headers = null,
  params = null
) => {
  return instance({
    method: method,
    url: url,
    data: data,
    headers: headers,
    params: params,
  });
};