import React from 'react';

function InputWithButton({ onButtonClick, ...inputProps }) {
  return (
    <div className="input-with-button">
      <input type="text" className="input" {...inputProps} />
      <button className="input-button" onClick={onButtonClick}>
        Go
      </button>
    </div>
  );
}

export default InputWithButton;
