import React from 'react';
import { useNavigate } from "react-router-dom";
import Button from "../../components/ui/Button";
import { amountFormat } from '../../utils/amountFormat.js';

const CartPopup = ({ product, selectedColorOption, total, isOpen, onClose }) => {
  const navigate = useNavigate();

  const onNavigate = () => {
    navigate(`/cart`);
  }

  console.log("selectedColorOption in popup:", selectedColorOption)

  return (
    <div className={`cart-popup ${isOpen ? 'show' : ''}`}>
      <div className="cart-popup-content d-flex">
        <div className="cart-popup-content-img d-flex align-items-center" style={{'width': '120px'}}>
          <img loading="lazy" src={product.productImages} alt="" />          
        </div>
        <div className="cart-popup-content-data w-100 d-flex flex-column justify-content-between">
          <h4 className='m-0 fw-bold'>{product.productName} ({selectedColorOption})</h4>
          <p className='small'>Price: ₹{amountFormat(total)}</p>
          <Button className={'w-100 close-popup'} onClick={() => {onClose(); onNavigate();}}>
              View cart &#8594;
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CartPopup;
